<mat-tab-group>
  <mat-tab label="About">
    <p>
      Digimap’s Pilot Collection provides access to data that we are trying out and evaluating against demand from
      users. As such, the data included in Pilot Digimap are subject to revision and update. If you need to rely on the
      persistence of data within Pilot Digimap for your research or teaching please get in touch with us beforehand to
      discuss.
    </p>
    <p>
      Pilot Digimap is free at the point of use for staff and students at subscribing higher and further education
      institutions and research councils. No specific Pilot Digimap subscription is required; access accompanies a
      subscription to any other collection.
    </p>
    <p>
      Pilot Digimap comprises a single application for both creating maps online and for downloading data which enables
      further analysis and investigation in other packages. Not all datasets are available for both data download and
      mapping:
    </p>
    <ul>
      <li>Use the mapping function to view, annotate and print maps online.</li>
      <li>Use the Data Download tab to download data and load it into a GIS or CAD package for further manipulation</li>
    </ul>
  </mat-tab>
  <mat-tab label="Getting Started">
    <h3>Log in</h3>
    <ul>
      <li>Log in to Digimap via the button in the top right of this page.</li>
      <li>
        Use your university or college login details (you might also use these to log in to your library catalogue or
        other online resources provided by your institution).
      </li>
      <li>Contact your local IT support with username/password issues – Digimap cannot help with this.</li>
      <li>Once logged in, you can access any Digimap Collection to which your institution has subscribed.</li>
    </ul>
    <h3>Applications</h3>
    <p>
      With Digimap you can view, customise and print maps. They all work in the same way but some offer additional
      features to make the most of their particular maps. You can:
    </p>
    <ul>
      <li>select your area of interest by searching, or zooming and panning</li>
      <li>view maps at one of our pre-defined scales</li>
      <li>annotate maps with symbols, lines, polygons and labels using drawing tools</li>
      <li>add buffer zones</li>
      <li>measure map features</li>
      <li>print maps at A4 to A0 size in PDF, PNG or JPG format</li>
      <li>customise map feature display</li>
    </ul>
    <h3>Download Data</h3>
    <p>Digimap allows you to download map data for use, for example, in GIS software or CAD software. It allows:</p>
    <ul>
      <li>selection of area of interest by drawing on the map, or with coordinates or National Grid tiles</li>
      <li>order multiple map data product(s) in one order</li>
      <li>data available in various formats (varies between products)</li>
      <li>amend and reorder any previous orders</li>
    </ul>
    <h3>Guides</h3>
    <p><a href="/help/learning-materials/">Application guides</a> are available for Roam and Data Download.</p>
  </mat-tab>
  <mat-tab label="FAQ">
    <p>We provide Frequently Asked Questions on a range of topics – here are links to a selection of our FAQs:</p>
    <ul>
      <li><a href="/help/faqs/access/">Accessing Digimap Collections</a></li>
      <li><a href="/help/faqs/licensing/">Copyright and Licensing</a></li>
    </ul>
  </mat-tab>
</mat-tab-group>
