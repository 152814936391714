<mat-tab-group>
  <mat-tab label="About">
    <p>
      Lidar Digimap offers detailed Lidar data from the Environment Agency through a convenient, on-demand web
      interface. It also offers vertical aerial photography.
    </p>
    <p>
      Lidar data is a very accurate model of the earth’s surface. The data is captured by firing very rapid laser pulses
      (thousands of times per second) at the ground surface. By examining the laser energy reflected back from the
      ground the surface is captured as a dense cloud of 3D points. These points are then converted in to highly
      detailed terrain models of the surface of the earth and by examining the reflections from both the ground surface
      and the vegetation canopy it is possible to derive both Digital Terrain Models of the bare earth surface (DTM) and
      also Digital Surface Models (DSM) that show the vegetation canopy.
    </p>
    <p>
      Vertical aerial photography is an airborne mapping technique, which uses a high-resolution camera mounted
      vertically underneath the aircraft to capture reflected light in the red, green, blue and for some datasets, near
      infra-red spectrum. Images of the ground are captured at resolutions between 10cm and 50cm, and ortho-rectified
      using simultaneous LIDAR and GPS to a high spatial accuracy. The Environment Agency has been capturing vertical
      aerial photography data regularly since 2006. Vertical aerial photography has been captured on a project by
      project basis each ranging in coverage from a few square kilometres to hundreds of square kilometres. Historic
      data are available for some areas where we have carried out repeat surveys. Photography can be captured in all
      survey conditions, including incident response where it is used to derive flood outlines. The photography is
      supplied as a raster dataset in ECW (enhanced compressed wavelet) format as either a true colour (RGB), near
      infra-red (NIR) or a 4-band (RGBN) dataset.
    </p>
    <p>
      Uses of Lidar data are highly varied, from use in the creation of visual effects for virtual reality and film
      projects to archaeology, forestry management, flood and pollution modelling. Because it is so much more detailed
      than “traditional” digital terrain models, in some datasets down to 25cm resolution, there is a wealth of
      additional uses for the data. Looking at lines of sight for rural developments, mobile phone and wireless
      broadband signals amongst other things is easier and more accurate using Lidar data because of the inclusion of
      vegetation in the model.
    </p>
    <p>
      All the Lidar data included in Lidar Digimap is available under the Open Government Licence, and access is
      provided for free with a subscription to Aerial Digimap. You can view a list of the data available in our help
      pages <a href="/help/our-maps-and-data/lidar_products" target="lidar_data">here</a>.
    </p>
  </mat-tab>
  <mat-tab label="Getting Started">
    <h3>Log in</h3>
    <ul>
      <li>Log in to Digimap via the button in the top right of this page.</li>
      <li>
        Use your university or college login details (you might also use these to log in to your library catalogue or
        other online resources provided by your institution).
      </li>
      <li>Contact your local IT support with username/password issues – Digimap cannot help with this.</li>
      <li>Once logged in, you can access any Digimap Collection to which your institution has subscribed.</li>
    </ul>
    <h3>Applications</h3>
    <p>
      With Digimap you can view, customise and print maps. All collections work in the same way but some offer
      additional features to make the most of their particular maps.
    </p>
    <h3>Downloading Data</h3>
    <p>Digimap allows you to download map data for use, for example, in GIS software or CAD software. It allows:</p>
    <ul>
      <li>selection of area of interest by drawing on the map, or with coordinates or National Grid tiles</li>
      <li>order multiple map data product(s) in one order</li>
      <li>data available in various formats (varies between products)</li>
      <li>amend and reorder any previous orders</li>
    </ul>
    <iframe
      #youtube
      src=""
      width="560"
      height="315"
      data-src="https://www.youtube.com/embed/ssEEdESGjwE"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen></iframe>
    <h3>Guides</h3>
    <p><a href="/help/learning-materials/">Application guides</a> are available for Roam and Data Download.</p>
  </mat-tab>
  <mat-tab label="FAQ">
    <p>We provide Frequently Asked Questions on a range of topics – here are links to a selection of our FAQs:</p>
    <ul>
      <li><a href="/help/faqs/lidar/">Digimap Lidar Collection</a></li>
      <li><a href="/help/faqs/access/">Accessing Digimap Collections</a></li>
      <li><a href="/help/faqs/licensing/">Copyright and Licensing</a></li>
    </ul>
  </mat-tab>
</mat-tab-group>
