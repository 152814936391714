<main>
  <div class="subs-container center-block">
    <div class="subscribe-container">
      <a class="btn btn-info subscribe-btn" href="https://subscriptions.edina.ac.uk/institutions">Subscribe &raquo;</a>
    </div>

    <h2>New prices for academic year <strong>2024/25</strong>:</h2>

    <h4>
      Please see the video
      <a href="https://www.youtube.com/watch?v=81_8YNrTJM0" alt="Instructions on subscribing to Digimap">
        how to subscribe to Digimap
      </a>
      for help on the subscription process.
    </h4>

    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="tab-label"><strong>Prices for Higher Education</strong></div>
        </ng-template>

        <table class="table">
          <thead>
            <tr>
              <th scope="col">&nbsp;</th>
              <th colspan="10" scope="colgroup">JISC Band</th>
            </tr>
            <tr>
              <th scope="col">&nbsp;</th>
              <th *ngFor="let band of bands" scope="col" class="band-number">{{ band }}</th>
            </tr>
          </thead>
          <tbody>
            <!-- The bundles -->
            <tr class="group-title highlighted-rows">
              <td colspan="11">Bundle Package Deals</td>
            </tr>
            <tr class="highlighted-rows" *ngFor="let product of getBundles()">
              <td class="product-name">
                <a routerLink="./" fragment="bundle-details">
                  {{ product.name }}
                </a>
              </td>
              <td *ngFor="let band of bands" class="price">£{{ product.prices[band - 1] | number }}</td>
            </tr>

            <!-- The individual products -->
            <tr class="group-title">
              <td colspan="11">Individual Collection Prices</td>
            </tr>
            <tr *ngFor="let product of getProducts()">
              <td [ngSwitch]="product.name" class="product-name" [ngClass]="{ 'is-discount': product.isDiscount }">
                <ng-container *ngSwitchCase="'aerial-lidar-special-case'">
                  <a routerLink="/aerial">Aerial</a> &amp; <a routerLink="/lidar">Lidar</a>
                </ng-container>
                <ng-container *ngSwitchCase="'geology-special-case'">
                  <a routerLink="/geology">Geology</a>
                  (incorporating
                  <a href="/help/our-maps-and-data/geology_overview/#coal-authority-data">The Coal Authority data</a>)
                </ng-container>
                <a *ngSwitchDefault [href]="product.link">
                  {{ product.name }}
                </a>
                <sup *ngIf="product.daggers">
                  <!-- renders one or more dagger symbols for footnotes -->
                  <ng-container *ngFor="let _ of [].constructor(product.daggers)">✝</ng-container>
                </sup>
              </td>
              <td *ngFor="let band of bands" class="price">£{{ product.prices[band - 1] | number }}</td>
            </tr>
          </tbody>
        </table>

        <p>Prices are exclusive of VAT.</p>
        <p>
          <em
            >✝ To be eligible to subscribe to NGD, Historic, Society Digimap, Landclan, or the National Tree Map
            institutions must subscribe to Digimap OS.
          </em>
          <br />
          <em>✝✝ Aerial Extra must be bought alongside either 'Aerial & Lidar' or the Ultimate or Premium bundles. </em>
        </p>

        <h3 name="bundle-details">Bundle Details</h3>
        <table class="table collection-details">
          <thead>
            <tr>
              <th scope="col">Collection</th>
              <th scope="col">Ultimate</th>
              <th scope="col">Premium</th>
              <th scope="col">Standard</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><strong>OS</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
            </tr>
            <tr>
              <td><strong>Marine</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
            </tr>
            <tr>
              <td><strong>Geology</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
            </tr>
            <tr>
              <td><strong>Historic</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
            </tr>
            <tr>
              <td><strong>Environment</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
            </tr>
            <tr>
              <td><strong>Aerial</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
            </tr>
            <tr>
              <td><strong>Lidar</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
            </tr>
            <tr>
              <td><strong>Global</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
            </tr>
            <tr>
              <td><strong>Society</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
            </tr>
            <tr>
              <td><strong>Verisk</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
            </tr>
            <tr>
              <td><strong>agCensus</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
            </tr>
            <tr>
              <td><strong>OS National Geographic Database (NGD)</strong></td>
              <td class="tick"><span role="img" aria-label="available">Upgrade available</span></td>
              <td class="tick"><span role="img" aria-label="available">Upgrade available</span></td>
              <td class="tick"><span role="img" aria-label="available">Upgrade available</span></td>
            </tr>
            <tr>
              <td><strong>National Tree Map</strong></td>
              <td class="tick"><span role="img" aria-label="available">Upgrade available</span></td>
              <td class="tick"><span role="img" aria-label="available">Upgrade available</span></td>
              <td class="tick"><span role="img" aria-label="available">Upgrade available</span></td>
            </tr>
            <tr>
              <td><strong>Aerial Extra</strong></td>
              <td class="tick"><span role="img" aria-label="available">Upgrade available</span></td>
              <td class="tick"><span role="img" aria-label="available">Upgrade available</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
            </tr>
            <tr>
              <td><strong>Landclan</strong></td>
              <td class="tick"><span role="img" aria-label="available">Upgrade available</span></td>
              <td class="tick"><span role="img" aria-label="available">Upgrade available</span></td>
              <td class="tick"><span role="img" aria-label="available">Upgrade available</span></td>
            </tr>
          </tbody>
        </table>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="tab-label"><strong>Prices for Further Education</strong></div>
        </ng-template>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">JISC Band</th>
              <th scope="col">
                <a routerLink="./" fragment="bundle-details"> Ultimate Bundle </a>
              </th>
              <th scope="col">
                <a routerLink="./" fragment="bundle-details"> Premium Bundle </a>
              </th>
              <th scope="col">
                <a routerLink="./" fragment="bundle-details"> Standard Bundle </a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>C, D, E</td>
              <td>£480</td>
              <td>£379</td>
              <td>£281</td>
            </tr>
            <tr>
              <td>F, G, H</td>
              <td>£432</td>
              <td>£347</td>
              <td>£252</td>
            </tr>
            <tr>
              <td>I, J</td>
              <td>£358</td>
              <td>£286</td>
              <td>£225</td>
            </tr>
          </tbody>
        </table>

        <p>Prices are exclusive of VAT.</p>

        <table class="table collection-details" name="bundle-details">
          <thead>
            <tr>
              <th scope="col">Collection</th>
              <th scope="col">Ultimate</th>
              <th scope="col">Premium</th>
              <th scope="col">Standard</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><strong>OS</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
            </tr>
            <tr>
              <td><strong>Marine</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
            </tr>
            <tr>
              <td><strong>Geology</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
            </tr>
            <tr>
              <td><strong>Historic</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
            </tr>
            <tr>
              <td><strong>Environment</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
            </tr>
            <tr>
              <td><strong>Aerial</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
            </tr>
            <tr>
              <td><strong>Lidar</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
            </tr>
            <tr>
              <td><strong>Global</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
            </tr>
            <tr>
              <td><strong>Society</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
            </tr>
            <tr>
              <td><strong>Verisk</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
            </tr>
            <tr>
              <td><strong>agCensus</strong></td>
              <td class="tick"><span role="img" aria-label="available">&#10003;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
              <td class="cross"><span role="img" aria-label="not available">&#10008;</span></td>
            </tr>
          </tbody>
        </table>
      </mat-tab>
    </mat-tab-group>
  </div>
</main>
