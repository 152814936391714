<mat-tab-group>
  <mat-tab label="About">
    <p>
      agCensus Digimap offers access to a time-series database of grid square based data derived from the regular
      Agricultural Censuses of England, Scotland and Wales.
    </p>
    <p>
      The Agricultural Census is conducted in June each year by the government departments dealing with Agriculture and
      Rural Affairs for Scotland, England, and Wales. Farmers are surveyed in each year via a postal questionnaire, with
      the farmer declaring the agricultural activity on their land. In Scotland the census covers all major agricultural
      holdings, but in England and Wales a stratified sample of holdings are surveyed. Data for non-surveyed farms is
      extrapolated from previous years and trends on comparable farms. The respective government departments publish
      information relating to farm holdings for recognised geographies for c.150 items of data. Algorithms developed by
      EDINA convert small area data provided by the government agencies into grid squares of 2, 5 or 10 km.
    </p>
    <p>
      You can view a list of all the data available in our help pages
      <a href="/help/our-maps-and-data/agcensus_products/" target="agcensus_data">here</a>.
    </p>
  </mat-tab>
  <mat-tab label="Getting Started">
    <h3>Log in</h3>
    <ul>
      <li>Log in to Digimap via the button in the top right of this page.</li>
      <li>
        Use your university or college login details (you might also use these to log in to your library catalogue or
        other online resources provided by your institution).
      </li>
      <li>Contact your local IT support with username/password issues – Digimap cannot help with this.</li>
      <li>Once logged in, you can access any Digimap Collection to which your institution has subscribed.</li>
    </ul>
    <h3>Applications</h3>
    <p>
      With Digimap you can view, customise and print maps. They all work in the same way but some offer additional
      features to make the most of their particular maps.
    </p>
    <h3>Download Data</h3>
    <p>Digimap allows you to download map data for use, for example, in GIS software or CAD software. It allows:</p>
    <ul>
      <li>selection of area of interest by drawing on the map, or with coordinates or National Grid tiles</li>
      <li>order multiple map data product(s) in one order</li>
      <li>data available in various formats (varies between products)</li>
      <li>amend and reorder any previous orders</li>
    </ul>
    <iframe
      #youtube
      src=""
      width="560"
      height="315"
      data-src="https://www.youtube.com/embed/ssEEdESGjwE"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen></iframe>
    <h3>Guides</h3>
    <p>
      <a href="/help/learning-materials/">Application guides</a>
      are available for Data Download.
    </p>
  </mat-tab>
  <mat-tab label="FAQ">
    <p>We provide Frequently Asked Questions on a range of topics – here are links to a selection of our FAQs:</p>
    <ul>
      <li><a href="/help/faqs/agcensus/">Digimap AgCensus Collection</a></li>
      <li><a href="/help/faqs/access/">Accessing Digimap Collections</a></li>
      <li><a href="/help/faqs/licensing/">Copyright and Licensing</a></li>
    </ul>
  </mat-tab>
</mat-tab-group>
